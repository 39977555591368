
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.content {
  padding: 7rem 0;
  background-image: linear-gradient(to right bottom, #ffffff, #fcfbfc, #f9f8f8, #f6f4f4, #f3f0f0);
  font-family: "Poppins", sans-serif;
}


h2 {
  font-size: 20px;
}


.content .bg {
  background-size: cover;
  background-position: center;
}

.content a {
  color: #888;
  text-decoration: underline;
  margin-bottom: 10px;
}

.content .btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}

.content .btn:active {
  transform: scale(0.96);
}


.social-login a {
  text-decoration: none;
  position: relative;
  text-align: center;
  color: #413afc;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
}
.social-login .bi.bi-arrow-right {
  color: #413afc;
}

.social-login a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .social-login a:hover {
  color: #fff;
} */

.social-login a.facebook {
  background: #3b5998;
}

.social-login a.facebook:hover {
  background: #344e86;
}

.social-login a.twitter {
  background: #1da1f2;
}

.social-login a.twitter:hover {
  background: #0d95e8;
}

.social-login a.google {
  background: #ea4335;
}

.social-login a.google:hover {
  background: #e82e1e;
}

.contents .title:after {
  content: '';
  background-color: #00A9EF;
  height: 3px;
  width: 80px;
  /* margin: 10px 0 0; */
  display: block;
  clear: both;
}
.contents .title-admin:after {
  content: '';
  background-color: #00A9EF;
  height: 3px;
  width: 180px;
  /* margin: 10px 0 0; */
  display: block;
  clear: both;
}

.content .img-slide {
  animation: transitionLeft 0.45s;
}

@keyframes transitionLeft {
  from {
    opacity: 0;
    transform: translateX(-60px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.contents {
  animation: transitionRight 0.45s;
}

@keyframes transitionRight {
  from {
    opacity: 0;
    transform: translateX(60px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
